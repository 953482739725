<template>
  <div class="edit-password">
    <el-row style="margin-bottom: 20px;"
            type="flex"
            justify="space-between">
      <el-col :span="6">
        <h2>修改密码</h2>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :span="18">

        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px">

          <el-form-item label="旧密码">
            <el-input v-model="form.oldPassword"
                      show-password></el-input>
          </el-form-item>

          <el-form-item label="新密码">
            <el-input v-model="form.newPassword"
                      show-password></el-input>
          </el-form-item>

          <el-form-item label="确认新密码">
            <el-input v-model="form.newPassword2"
                      show-password></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit">修 改</el-button>
            <el-button @click="reset">重 置</el-button>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { updateUserPassword } from '@/api/profile.js'
export default {
  data () {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      rules: {
        oldPassword: [
          { required: true, trigger: 'blur', message: '旧密码必须' }
        ],
        newPassword: [
          { required: true, trigger: 'blur', message: '新密码必须' }
        ],
        newPassword2: [
          { required: true, trigger: 'blur', message: '确新密码必须' }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          updateUserPassword(this.form).then(res => {
            const { code, msg } = res
            if (code === 0) {
              this.$message.success(msg || '修改成功')
              this.reset()
            } else {
              this.$message.error(msg || '修改失败')
            }
          })
        }
      })
    },
    reset () {
      this.form.oldPassword = ''
      this.form.newPassword = ''
      this.form.newPassword2 = ''
    }
  },
  activated () {
    this.reset()
  }
}
</script>

<style>
</style>
